.inner-text {
  color: #D71921;
}

.product_title {
  text-transform: uppercase !important;
  color: #D71921 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 120% !important;
  letter-spacing: 1.01px !important;
}

.product_heading {
  color: #161A1D !important;
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

.product_paragraph {
  color: rgba(0, 0, 0, 0.80) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  /* 24px */
  letter-spacing: -0.176px !important;
}

.product_sub_heading {
  color: #161A1D !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
}

@media (min-width:600px) {
  .custom-h-YT-v {
    height: 500px;
  }
}

@media (max-width:600px) {
  .custom-h-YT-v {
    height: 200px;
  }
}

@media (max-width:768px) {
  .product_title {
    font-size: 12px !important;
  }

  .product_heading {
    font-size: 18px !important;
  }

  .product_paragraph {
    font-size: 14px !important;
  }

  .product_sub_heading {
    font-size: 14px !important;
  }
}

.card_heading {
  color: #262626 !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

.italic_text {
  font-style: italic !important;
}

.card_paragraph {
  color: #262626 !important;
  font-size: 16px !important;
  font-style: Normal;
  font-weight: 400 !important;
  line-height: 120% !important;
}

.card_link {
  /* color: #161A1D !important; */
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

.card_2_text {
  color: #262626 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  padding-right: 10px !important;
}

@media (max-width:768px) {
  .card_2_text {
    padding-right: 0px !important;
  }
}

@media (max-width:768px) {
  .card_heading {
    font-size: 18px !important;
  }

  .card_paragraph {
    font-size: 14px !important;
  }

  .card_2 .card_link {
    font-size: 14px !important;
  }

  .card_2_text {
    font-size: 14px !important;
  }
}

.card {
  border: none !important;
  /* box-shadow: 0px 0px 0px 0px !important */
}

a {
  font-weight: bold;
  color: #161A1D;
  font-size: 16px;
  font-style: normal;
  text-decoration: none;
}

h4 {
  color: #262626;
  font-size: 24px;
  font-weight: 600;
}

.custom_color {
  color: #D42027 !important;
  font-size: 32px;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

@media (max-width:768px) {
  .custom-color {
    font-size: 18px !important;
  }
}

.custom-padding {
  padding-top: 10px;
  padding-left: 15px;
  color: #000 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

@media (max-width:768px) {
  .custom-padding {
    font-size: 14px !important;
  }
}

.custom-bg-color {
  background-color: #F5F5F5;
}

.custom_border {
  border-radius: 20px;
  border: 2px solid #D32027;
}

.custom_color_3 {
  color: #D71921 !important;
}

.custom_padding {
  padding: 10px;
}

.custom_style {
  font-size: 55.714px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 120% !important;
}

.custom_style_2 {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  padding-top: 10px;
}

@media (max-width:1024px) {
  .custom_style_2 {
    margin-top: 7px !important;
  }
}

@media (max-width:768px) {
  .custom_style_2 {
    font-size: 16px !important;
  }
}

.custom_font_p {
  color: #000;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
}

@media (max-width:768px) {
  .custom_font_p {
    font-size: 14px !important;
  }
}

.custom_color_card {
  background-color: #FFF;
  border-radius: 0 0 20px 20px;
}

.half_card {
  background-color: #F5F5F5 !important;
  height: 105px !important;
  border-radius: 20px 20px 0px 0px !important;
  padding-top: 10px;
}

@media (max-width:2000px) {
  .half_card {
    height: 140px;
  }
}

@media (min-width:991px) {
  .cards_custom_design {
    border-radius: 24px !important;
    background: #FFF !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
    padding: 7px !important;
    /* height: 80% !important; */
  }
}

@media (max-width:991px) {
  .cards_custom_design_1 {
    border-radius: 24px !important;
    background: #FFF !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
    padding: 7px !important;
    /* height: 80% !important; */
  }
}

.cards_custom_design img {
  width: 64px;
  height: auto;
  flex-shrink: 0;
}

.card_heading {
  font-size: 15px;
  color: #D71921;
  font-weight: bold;
}

.card_text {
  font-size: 13px;
  color: #D71921;
}

.card-text_3 {
  color: #262626 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  height: 200px;
  margin-left: 20px;
  margin-right: 20px;
}

.owl-nav.disabled {
  display: block !important;
}

.owl-prev,
.owl-next {
  top: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50% !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}

.owl-prev {
  left: -40px;
}

.owl-prev span {
  font-weight: bold;
}

.owl-next {
  right: -40px;
}

@media (max-width:900px) {
  .owl-prev {
    left: -60px;
  }

  .owl-next {
    right: -60px;
  }
}

@media (max-width:600px) {
  .owl-prev {
    left: -20px !important;
  }

  .owl-next {
    right: -20px !important;
  }
}

.owl-prev:hover,
.owl-next:hover {
  background-color: #D9DADB !important;
  color: black !important;
}

.swiper-button-prev,
.swiper-button-next {
  height: 50px !important;
  width: 50px !important;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  text-align: center;
  line-height: 50px;
  background: #fff;
  border-radius: 50% !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}

.swiper-button-prev::after {
  color: black !important;
  font-size: 12px !important;
}

.swiper-button-next::after {
  color: black !important;
  font-size: 12px !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #D9DADB !important;
  color: white !important;
}

.custom-color-body {
  background-color: #F8F8F8;
  border-radius: 0 0 24px 24px;
}

.card_custom_font_size {
  color: #000 !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

@media (max-width:992px) {
  .card_custom_font_size {
    font-size: 20px !important;
  }
}

.swiper-wrapper {
  align-items: center !important;
}

.custom_slide_design .swiper-slide-next {
  filter: blur(5px) !important;
  width: 400px;
  height: auto !important;
  z-index: 0 !important;
}

.custom_slide_design .swiper-slide-next .card_custom_font_size {
  font-size: 18px !important;
}

.custom_slide_design .swiper-slide-next .card-text {
  font-size: 15px;
}

.custom_slide_design .swiper-slide-next .card-img-top {
  height: 250px !important;
}

.custom_slide_design .swiper-slide-prev {
  filter: blur(5px) !important;
  width: 400px;
  height: auto !important;
  z-index: 0 !important;
}

@media (max-width:769px) {
  .custom_slide_design .swiper-slide-prev {
    filter: blur(5px) !important;
    width: 300px;
    height: auto !important;
    z-index: 0 !important;
  }

  .custom_slide_design .swiper-slide-next {
    filter: blur(5px) !important;
    width: 300px;
    height: auto !important;
    z-index: 0 !important;
  }
}

.custom_slide_design .swiper-slide-prev .card_custom_font_size {
  font-size: 1.15rem !important;
}

.custom_slide_design .swiper-slide-prev .card-text {
  font-size: 0.7rem !important;
  padding-right: 30px !important;
}

.custom_slide_design .swiper-slide-prev .card-img-top {
  height: 250px !important;
}

.custom_slide_design .swiper-slide-prev .ne_card .img img {
  height: 260px !important;
}

.custom_slide_design .swiper-slide-next .ne_card .img img {
  height: 260px !important;
}

.custom_slide_design .swiper-slide-prev .ne_card .card_heading {
  font-size: 20px !important;
}

.custom_slide_design .swiper-slide-prev .ne_card .card_2_text {
  font-size: 12px !important;
}

.custom_slide_design .swiper-slide-next .ne_card .card_heading {
  font-size: 20px !important;
}

.custom_slide_design .swiper-slide-next .ne_card .card_2_text {
  font-size: 12px !important;
}

.custom_box_shadow_1 {
  margin-bottom: 10% !important;
}

/* .custom_slide_design .swiper-slide-prev{
  visibility: hidden !important;
} */
.custom_slide_design .swiper-slide-active {
  z-index: 11 !important;
}

.custom_slide_design .swiper-pagination-bullet-active {
  border-radius: 50px !important;
  background: #DA291C !important;
  width: 60px !important;
  height: 12px !important;
}

.swiper-pagination {
  bottom: -2px !important;
}

.card-img-top {
  height: 340px !important;
  cursor: pointer;
}

.card-img-slide {
  height: auto !important;
  cursor: pointer;
}

@media (max-width:1200px) {
  .card-img-top {
    height: 275px !important;
    cursor: pointer;
  }

}

.custom_color_111 {
  color: #D71921 !important;
}

.card_heading_3 {
  color: #D71921 !important;
  text-align: center !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

.card_text_3 {
  color: #D71921 !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
}

.card_outer_text {
  color: #D71921 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 120% !important;
}

@media (max-width:768px) {
  .card_heading_3 {
    font-size: 16px !important;
  }

  .card_text_3 {
    font-size: 14px !important;
  }

  .card_outer_text {
    font-size: 16px !important;
  }
}

.last_images img {
  max-width: 50% !important;
  max-height: auto !important;
}

@media (max-width:768px) {
  .card_custom_font_size {
    font-size: 18px !important;
  }

  .card-text_3 {
    font-size: 14px !important;
  }

  .last_images img {
    max-width: 90% !important;
    max-height: auto !important;
  }

}

@media (max-width:768px) {
  .custom_img_width img {
    width: 32px !important;
    height: 33px !important;
  }
}

.custom-color_3 {
  color: #D32027 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}

@media (max-width:768px) {
  .custom-color_3 {
    font-size: 16px !important;
  }
}

@media (max-width:768px) {
  .custom_hide_display .image_1 {
    display: none;
  }
}

@media (min-width:768px) {
  .custom_hide_display .image_2 {
    display: none;
  }
}

.custom-nav-tabs {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
  scrollbar-width: none !important;
  /* Hides the scrollbar for Firefox */
  -ms-overflow-style: none !important;
  /* Hides the scrollbar for IE and Edge */
}

.custom-nav-tabs::-webkit-scrollbar {
  display: none !important;
  /* Hides the scrollbar for Webkit (Chrome, Safari, etc.) */
}

@media (max-width:500px) {
  .custom-nav-item {
    flex: 0 0 auto !important;
  }
}

@media (max-width:768px) {
  .card_customm_padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

.new_slider .swiper-button-prev {
  visibility: hidden;
}

.new_slider .swiper-button-next {
  visibility: hidden;
}

@media (max-width:768px) {
  .new_slider .swiper-button-prev {
    visibility: visible;
  }

  .new_slider .swiper-button-next {
    visibility: visible;
  }
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.circle-tabs .class_1 {
  background-color: white !important;
  color: #D32027 !important;
  border: 2px solid #D32027 !important;
}

.circle-tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  display: flex !important;
  justify-content: space-around;
}

.circle-tabs .class_1::before {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='232' height='4' viewBox='0 0 852 4'><path d='M0 2H852' stroke-width='3'/></svg>");
  position: absolute;
  top: 54%;
  left: -232px;
  transform: translateY(-50%);
  background-color: #D32027;
  height: 2px;
}

.remove-svg>div:first-child .class_1::before {
  display: none;
  /* Hide SVG for the first child anchor tag within each .remove-svg container */
}

@media (min-width: 1400px) {
  .circle-tabs .class_1::before {
    left: -278px;
    /* Adjust as needed for smaller screens */
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='278' height='4' viewBox='0 0 852 4'><path d='M0 2H852' stroke-width='3'/></svg>");
  }
}

@media (max-width: 1199px) {
  .circle-tabs .class_1::before {
    left: -188px;
    /* Adjust as needed for smaller screens */
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='186' height='4' viewBox='0 0 852 4'><path d='M0 2H852'  stroke-width='3'/></svg>");
  }
}

@media (max-width: 991px) {
  .circle-tabs .class_1::before {
    left: -127px;
    /* Adjust as needed for smaller screens */
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='125' height='4' viewBox='0 0 852 4'><path d='M0 2H852' stroke-width='3'/></svg>");
  }
}

@media (max-width: 768px) {
  .circle-tabs .class_1::before {
    top: 143% !important;
    left: 21px;
    /* Adjust as needed for smaller screens */
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='2' height='35' viewBox='0 0 2 208'><path d='M1 0L1.00001 208' stroke='%23D32027' stroke-width='2'/></svg>");
    background-color: #D32027;
    height: 35px;
  }

  .circle-tabs {
    flex-direction: column;
  }

  .remove-svg>div:first-child .class_1::before {
    display: block;
  }

  .remove-svg-2>div:first-child .class_1::before {
    display: none;
  }
}

.circle-tabs p {
  color: #262626;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 15px;
}

.circle-Tabs-heading {
  color: #161A1D;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (max-width:767px) {
  .circle-tabs p {
    padding: 0px;
    font-size: 14px;
    padding-left: 15px;
    margin-top: 12px;
  }

  .circle-Tabs-heading {
    font-size: 14px;
  }
}

/* .card_customm_padding a:hover{
color: #D32027;
} */
.card_link {
  cursor: pointer;
}

.card_link:hover {
  color: #D32027 !important;
  /* Change to your desired color */
}

.skaj img {
  width: 216.206px !important;
  height: 65.808px !important;
}

@media (max-width:768px) {
  .skaj img {
    height: 36.808px !important;

  }
}

.custom_border_23 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notePad_text {
  color: #161A1D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  /* 16.8px */
}

/* .swiperslide_custom_width{
  width: 392px !important;
} */
@media (max-width:767px) {
  .cards_custom_design img {
    width: 48px !important;
    height: 48px !important;
  }
}

.Keunggulan_p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

@media (max-width:768px) {
  .Keunggulan_p {
    font-size: 14px;
  }
}

.card_background_image_1 {
  background-image: url(/public/assets/Air_Filter-removebg-preview.png);
  height: 86px;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-notepad_1 {
  margin-left: 2rem !important;
}

@media (min-width:1400px) {
  .card_background_image_1 {
    height: 99px;
  }

  .img-notepad_1 {
    margin-left: 1.7rem !important;
  }
}

@media (max-width:1200px) {
  .card_background_image_1 {
    height: 72px;
  }

  .img-notepad_1 {
    margin-left: 1.7rem !important;
  }
}

@media (max-width:991px) {
  .card_background_image_1 {
    height: 80px;
  }

  .img-notepad_1 {
    margin-left: 1.9rem !important;
  }
}

@media (max-width:767px) {
  .card_background_image_1 {
    height: 60px;
  }

  .img-notepad_1 {
    margin-left: 1.9rem !important;
    margin-top: 11px;
  }

  .notePad_text {
    margin-top: 1.8rem !important
  }
}

@media (max-width:399px) {
  .card_background_image_1 {
    height: 60px;
  }

  .img-notepad_1 {
    margin-left: 1.3rem !important;
    margin-top: 8px;
  }

  .notePad_text {
    margin-top: 1.5rem !important
  }
}

@media (max-width:380px) {
  .img-notepad_1 {
    margin-top: 1px;
  }

  .notePad_text {
    margin-top: 0.8rem !important
  }
}

@media (max-width:768px) {
  .notePad_text {
    font-size: 12px !important;
  }

  .img-notepad_1 {
    width: 27px;
    height: 27px;
  }
}

.card_background_image_2 {
  background-image: url(/public/assets/Air_Filter-removebg-preview.png);
  height: 86px;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width:1199px) {
  .card_background_image_2 {
    height: 72px;
  }
}

@media (max-width:991px) {
  .card_background_image_2 {
    height: 80px;
  }

  .margin_of_2 {
    margin-left: 2rem;
  }
}

@media (max-width:768px) {
  .margin_of_2 {
    margin-top: 13px;
  }
}

@media (max-width:474px) {
  .card_background_image_2 {
    height: 68px;
  }

  .margin_of_2 {
    margin-top: 13px;
  }
}

@media (max-width:430px) {
  .card_background_image_2 {
    height: 66px;
  }

  .margin_of_2 {
    margin-top: 3px;
    margin-left: 23px !important;
  }
}

@media (max-width:375px) {
  .margin_of_2 {
    margin-top: -1px;
  }
}

@media (min-width:1400px) {
  .card_background_image_2 {
    height: 99px;
  }
}

@media (max-width:1199px) {
  .card_background_image_2 {
    height: 72px;
  }
}

.card_background_image_3 {
  background-image: url(/public/assets/BrakePad&BrakeShoe.png);
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
}

.third_double_image {
  margin-top: 13px;
}

.text_334 {
  margin-top: 1.8rem !important;
}

.double_color_image {
  margin-top: -8px;
  background-color: rgb(182, 180, 180);
}

@media (max-width:1200px) {
  .third_double_image {
    margin-top: 2px;
  }

  .text_334 {
    margin-top: 1.3rem !important;
  }

  .double_color_image {
    margin-top: -14px;
  }
}

@media (max-width:768px) {
  .double_color_image {
    margin-top: -15px;
  }

  .card_background_image_3 {
    height: 127px;
  }
}

@media (max-width:768px) {
  .double_color_image {
    margin-top: 0px;
  }

  .text_334 {
    margin-top: 1.9rem !important;
  }

  .third_double_image {
    margin-top: 19px;
  }

  .card_background_image_3 {
    height: 135px;
  }

  .image_334 {
    margin-top: 2px;
  }

  .text_336 {
    margin-top: 1.2rem !important;
  }
}

@media (max-width:515px) {
  .card_background_image_3 {
    height: 143px;
  }
}

@media (max-width:428px) {
  .double_color_image {
    margin-top: -12px;
  }

  .text_334 {
    margin-top: 1.8rem !important;
  }

  .third_double_image {
    margin-top: 7px;
  }
}

@media (max-width:413px) {
  .text_334 {
    margin-top: 1.2rem !important;
  }
}

@media (max-width:373px) {
  .text_334 {
    margin-top: 0.8rem !important;
  }

  /* .double_color_image{
    margin-top: -9px;
  } */
  .image_334 {
    margin-top: -11px;
  }

  .text_336 {
    margin-top: 0.1rem !important;
  }
}

.image_338 {
  margin-top: 10px !important;
}

.text_338 {
  margin-top: 2rem !important;
}

@media (max-width:1200px) {
  .image_338 {
    margin-top: 4px !important;
  }

  .text_338 {
    margin-top: 1.9rem !important;
    margin-bottom: 1.7rem !important;
  }
}

.double_color_image_1 {
  margin-top: -8px;
  background-color: rgb(182, 180, 180);
}

@media (max-width:1199px) {
  .double_color_image_1 {
    margin-top: -19px;
  }
}

@media (max-width:768px) {
  .double_color_image_1 {
    margin-top: -22px;
  }
}

@media (max-width:767px) {
  .double_color_image_1 {
    margin-top: 8px;
  }

  .image_338 {
    margin-top: 32px !important;
  }

  .text_338 {
    margin-top: 2.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .text-339 {
    margin-top: 1rem !important;
  }
}

@media (max-width:443px) {

  /* .double_color_image_1 {
    margin-top: 8px;
  } */
  .image_338 {
    margin-top: 20px !important;
  }

  .text_338 {
    margin-top: 1.5rem !important;
    margin-bottom: 0px !important;
  }

}

@media (max-width:351px) {
  .double_color_image_1 {
    margin-top: 6px;
  }
}

@media (max-width:358px) {
  .image_340 {
    margin-top: 16px !important;
  }

  .text_340 {
    margin-top: 0.8rem !important;
  }

  .image_341 {
    margin-top: 0px !important;
  }

  .text_341 {
    margin-top: 0.8rem !important;
  }
}

.double_color_image_2 {
  margin-top: 0px !important;
}

.show_cards_1 {
  border-radius: 18px !important;
  background: #ECEDED;
  padding: 48px 56px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.show_cards_2 {
  border-radius: 18px !important;
  background: #ECEDED;
  padding: 48px 56px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.card_heading_233 {
  color: #161A1D;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.4px;
  /* 132% */
}

@media (max-width:767px) {
  .card_heading_233 {
    color: #161A1D;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.4px;
  }

  .show_cards_1 {
    padding: 24px 28px;
    justify-content: center;
    align-items: center;
  }

  .show_cards_2 {
    padding: 24px 28px;
    justify-content: center;
    align-items: center;
  }

  .show_cards_1 .img img {
    height: 143.721px;
    width: 120px;
  }

  .show_cards_2 .img img {
    height: 143.721px;
    width: 120px;
  }
}

@media (max-width:400px) {
  .show_cards_1 {
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
  }

  .show_cards_2 {
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
  }
}

.show_cards_3 {
  border-radius: 18px;
  background: #FDF3F3;
  padding: 48px 56px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

@media (max-width:767px) {
  .show_cards_3 {
    padding: 24px;
  }

  .show_cards_3 .img img {
    height: 143.721px;
    width: 120px;
  }
}

@media (max-width:400px) {
  .show_cards_3 {
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
  }
}

.custom_slide_design_1 .swiper-wrapper {
  height: auto !important;
}

.custom_slide_design_1 .swiper-pagination {
  position: relative !important;
  padding-top: 10% !important;
}

.popup-content {
  background: transparent !important;
  padding: 0px !important;
  border: none !important;
  width: 70% !important;
  height: auto !important;
}

.custom_pointer {
  cursor: pointer !important;
}

.swiper_slider_1 .swiper-wrapper {
  height: 41rem !important;
}

/* .swiper_slider_1 .swiper-wrapper .swiper-slide .card {
  height: 32rem !important;
} */

.swiper_slider_1 .swiper-button-prev {
  top: 43% !important;
}

.swiper_slider_1 .swiper-button-next {
  top: 43% !important;
}

.deskMt3 {
  margin-top: 3px !important;
}

.clientImg {
  width: 100%;
  height: auto;
  text-align: center;
  display: inline-block;
  /* Helps with centering if needed, though not necessary with text-align center */
}

.swiper-slide {
  height: 600px !important;
}

.logoIcon {
  padding-top: 50px;
}

.full-screen-60 {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60vw;
  height: 60vh;
  transform: translate(-50%, -50%);
  border: none;
}

.arrow-down {
  transform: rotate(90deg);
  transition: transform 0.3s;
  margin-top: -8px;
  margin-left: 10px;
}

.text-red {
  color: #D42027;
}

.arrow-red {
  filter: invert(28%) sepia(97%) saturate(7478%) hue-rotate(353deg) brightness(101%) contrast(105%);
}

.move-down {
  transition: margin-top 0.3s ease-in-out;
  margin-top: 100px;
  /* Adjust this value as needed */
}

.card2 {
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 87%;
  /* justify-content: space-between; Ensure the space is evenly distributed */
  padding: 20px;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
  border-radius: 10px;
}

.img-container {
  display: flex;
  justify-content: center;
  height: 300px;
  /* Set a fixed height for the image container */
  width: 100%;
  /* Ensure it takes up the full width */
}

.img-container img {
  max-height: 90%;
  object-fit: contain;
  /* Ensure the image fits within the container without distortion */
}

.img-container.zoom {
  transform: scale(1.1);
  /* Zoom in effect */
}

.zoom img {
  max-height: 100%;
  object-fit: contain;
  /* Ensure the image fits within the container without distortion */
}

.text-container {
  text-align: center;
  margin-top: 20px;
  flex-grow: 1;
  /* Allow the text container to grow and take up available space */
}

.card_heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card_2_text {
  font-size: 1rem;
  margin-bottom: 20px;
}

.link-container {
  display: flex;
  justify-content: left;
  margin-top: 20px;
}

.card_link {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: left;
  cursor: pointer;
  transition: color 0.3s;
}

.card_link.active {
  color: red;
}

.card_link img {
  margin-left: 5px;
  transition: transform 0.3s;
}

.card_link img.rotate {
  transform: rotate(90deg);
  transition: transform 0.3s;
  margin-top: -8px;
  margin-left: 10px;
}

.expandable-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.expandable-content.expanded {
  max-height: 400px;
  /* Adjust this value based on your content's maximum height */
}

/* Ensure the parent container allows for enough space */
.container.swiper_slider_1 {
  overflow: visible;
  padding-bottom: 50px;
  /* Adjust this value based on the maximum height of the expandable content */
}

.swiper {
  height: 690px !important;
}

.left-header {
  text-align: left;
}

.icons {
  margin-right: 10px;
}

.pl3 {
  padding-left: 40px;
}

.pl30 {
  margin-left: 30px;
}

.w100 {
  width: 200px;
  vertical-align: top;
}

.mt100 {
  margin-top: 100px;
}

.naration {
  font-size: 0.875rem !important;
}

.mb18 {
  margin-bottom: 18px;
}

/* TODO: Adjustment tab */

/* Base styles (default for desktop) */
.toggle-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* Light shadow effect */
  width: 420px;
  /* Desktop width */
  border-radius: 8px;
}

/* Tablet view (between 768px and 1024px) */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .toggle-button-group {
    width: 320px;
    /* Adjust the width for tablet view */
    padding: 8px;
    font-size: 14px;
    /* Adjust the font size for smaller screens */
  }
}

/* Mobile view (below 768px) */
@media only screen and (max-width: 768px) {
  .toggle-button-group {
    width: 80%;
    /* Full width for mobile */
    padding: 6px;
    font-size: 12px;
    /* Smaller font size for mobile */
    box-shadow: none;
    /* Optional: Remove shadow for mobile */
  }

  .toggle-button-group .toggle-button {
    padding: 8px 10px;
    /* Adjust padding for smaller buttons */
    width: 50%;
    /* Stack buttons equally in two columns */
  }
}

.toggle-button {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button.active {
  background-color: #DF1D1D;
  /* Red color */
  color: white;
}

.toggle-button:not(.active) {
  background-color: #ffffff;
  color: #aaaaaa;
  /* Grey color */
}

.divider {
  margin: 0 15px;
  font-size: 20px;
  color: grey;
}

/* CSS for the button alignment and overall layout */


/* Ensures the Select component has the same height as the button */
.selectCar .slect__control {
  min-height: 38px;
  text-align: left;
  font-weight: normal;
}

.label-search {
  padding-top: 25px;
}

.btn-search {
  padding-top: 20px;
}

.btn-search-2 {
  padding-top: 10px;
}

/* Default style (Desktop) */
.label-title {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  /* Ukuran font untuk desktop */
}

.label-tab {
  font-size: 16px;
  /* Ukuran font untuk mobile */
}

.select-box {
  font-size: 14px;
}

.product-select {
  font-size: 16px;
  font-weight: normal;
  color: black;
}

/* Tablet (width between 768px and 1024px) */
@media (max-width: 1024px) {
  .label-title {
    font-size: 14px;
    /* Ukuran font untuk tablet */
  }

  .label-tab {
    font-size: 16px;
    /* Ukuran font untuk mobile */
  }

  .select-box {
    font-size: 14px;
  }

  .product-select {
    font-size: 14px;
    font-weight: normal;
  }
}

/* Mobile (width below 768px) */
@media (max-width: 767px) {
  .label-title {
    font-size: 12px;
    /* Ukuran font untuk mobile */
  }

  .label-tab {
    font-size: 14px;
    /* Ukuran font untuk mobile */
  }

  .select-box {
    font-size: 12px;
  }

  .product-select {
    font-size: 12px;
    font-weight: normal;
  }
}

/* showing product */

.product-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.product-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

p {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

/* .pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
} */

/* .pagination-btn {
  background-color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */

.product-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  overflow: hidden;
  /* Hide overflow to create a clean sliding effect */
  position: relative;
}

.product-list-wrapper {
  width: 100%;
  overflow: hidden;
  /* Hide overflowing products */
}

.product-list {
  display: flex;
  transition: transform 0.5s ease-in-out;
  /* Smooth sliding effect */
  width: calc(100% * 2);
  /* Ensure the width can accommodate all slides */
}

.product-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  margin: 10px;
  flex: 1 1 23.6%;
  /* 4 items per row on desktop */
  max-width: 300px;
}

/* Tablet: 2 or 3 items per row */
@media (max-width: 1024px) {
  .product-card {
    flex: 1 1 45%;
    /* 2 items per row */
    max-width: 200px;
    /* Ensure it expands fully within available space */
  }
}

/* Mobile: 1 item per row */
@media (max-width: 768px) {
  .product-card {
    flex: 1 1 100%;
    /* 1 item per row */
    max-width: 300px;
    /* Expand to full width */
  }
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination-btn {
  background-color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-input {
  display: block;
  width: 100%;
  padding: .2rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.0;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* CustomSelect.css */
.custom-select .select__multi-value {
  display: inline-flex;
  /* Keep items in a single line */
  flex-wrap: nowrap;
  /* Prevent wrapping */
  margin-right: 8px;
  /* Add spacing between tags */
  align-items: center;
  /* Vertically center the items */
  width: 200px;
}

.custom-select .select__multi-value__label {
  background-color: #d9d9d9;
  /* Change background color of the label */
  color: black;
  /* Change text color */
  padding: 4px 8px;
  /* Add padding for better appearance */
}

.custom-select .select__multi-value__remove {
  color: white;
  /* Color for the remove button */
  cursor: pointer;
  /* Change cursor to pointer */
}

.custom-select .select__multi-value__remove:hover {
  background-color: #0056b3;
  /* Hover background color */
  color: white;
  /* Hover text color */
}

.custom-select .select__control {
  border-color: var(--border-color, #ced4da);
  /* Default border color */
}

.custom-select .select__control--is-focused {
  border-color: #007BFF;
  /* Focused border color */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  /* Focused shadow */
}

/* Prevent text from wrapping in the control */
.custom-select .select__control {
  white-space: nowrap;
  overflow: hidden;
}

.error .select__control {
  border: 1px solid red;
}

.required {
  color: red;
  margin-left: 5px;
}

.error-message {
  color: red;
  font-size: 10px;
}
.success-message {
  color: white;
  font-size: 10px;
}

.not-found {
  font-size: 22px;
}

.gray {
  font-size: 16px !important;
  font-weight: normal !important;
  color: grey !important;
}

/* CSS khusus untuk perangkat seluler */
@media (max-width: 767px) {
  .gray {
    font-size: 12px !important;
  }
}
.dropdown-heading-value{
  font-size:17px !important;
  font-weight: normal !important;
  color: black !important;

}

.text-left{
  text-align: left;
}


.part-number-item {
  background-color: #E9E9E9;
  color: #000000;
  padding: 20px 5px;
  border-radius: 5px;
  font-size: 0.9em;
  text-align: center;
  flex: 1 1 calc(50% - 5px); /* Membagi menjadi 2 kolom */
  margin-top: 10px;
  height: 25px; /* Membuat tinggi background sama */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Menambahkan jarak antar elemen */
  margin-left: 5px;
}


@media (max-width: 576px) {
  .part-number-item {
    font-size: 0.8em;
    padding: 6px 10px;
    height: 35px; /* Membuat tinggi background sama */
  }
}

.pagination {
  display: flex;
  justify-content: center; /* Center secara horizontal */
  align-items: center;     /* Center secara vertikal */
  margin-bottom: 30px;
}
.pagination-btn {
  margin: 0 5px; /* Tambahkan sedikit margin antara tombol */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  color: #ccc;
  font-size: 18px;
}
.pagination-btn.active {
  background-color: #d34a43;
  color: white;
}
.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.car-select {
  margin-top: 0.5rem; /* Atur jarak sesuai kebutuhan */
}

.model-select {
  margin-top: 0.5rem; /* Atur jarak sesuai kebutuhan */
}

.year-select {
  margin-top: 0.5rem; /* Atur jarak sesuai kebutuhan */
}

.product-select {
  margin-top: 0.5rem; /* Atur jarak sesuai kebutuhan */
}

.item-renderer, .dropdown-heading-value{
  font-weight: 400 !important;
  font-size: 16px !important;
}

@media (max-width: 768px) {
  .item-renderer, .dropdown-heading-value {
    font-size: 13px !important;
  }
}
@media (max-width: 768px) {
  .product-section {
    text-align: center;
  }
}

.btn-src{
  width: 200px;
}

.pagination-btn {
  color: red;
}

