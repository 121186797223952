/* @font-face { font-family: CustomToyota; src: url('../public/assets/fonts/Toyota-Font/Toyota Type.ttf'); } */
@font-face {
  font-family: CustomToyota;
  src: url('../public/assets/fonts/TTF/ToyotaType-Regular.ttf');
}

@font-face {
  font-family: CustomToyotaLight;
  src: url('../public/assets/fonts/TTF/ToyotaType-Light.ttf');
}

/* *{
  font-family: 'CustomToyota';
} */
h1 {
  font-family: "CustomToyota";
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 600 !important;
}

b {
  font-family: "CustomToyota";
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.wrapper-pink-bg .disclaimer {
  font-size: 16px !important;
}

p {
  /* font-size: 1.125rem;  */
  font-style: normal;
  font-weight: 300;
}

.custom-font-light-one-rem {
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  color: #161A1D;
}

.navbar-toggler {
  border: none !important;
  /* Remove border */

}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* media query for font in mobile devices */
@media only screen and (max-width: 600px) {
  h1 {
    font-family: "CustomToyota";
    font-size: 1.25rem !important;
    font-style: normal;
    font-weight: 600 !important;
  }

  b {
    font-family: "CustomToyota";
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
  }

  .custom-font-light-one-rem {
    font-family: "CustomToyota";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    color: #161A1D;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar {
  background-color: black;
}

.navbar .nav-item .nav-link {
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.nav-1 .nav-item {
  color: #000 !important;
  cursor: pointer;
}

.nav-item {
  cursor: pointer;
}

.text-hover-danger:hover {
  color: #dc3545;
  /* Change text color to Bootstrap's danger color on hover */
}

.custom-border-radius:hover {
  border-radius: 30px !important;
  background-color: #fff;
  color: #000 !important;
}

.custom-border-radius {
  border-radius: 30px !important;
}

.hover-color:hover {
  color: #dc3545 !important;
}

/* .navbar .nav-item .nav-link:hover{
background-color: #fff;
color: #000;
cursor: pointer;
border-radius: 30px !important;
}
.nav-1 .nav-item .nav-link {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: lighter !important;
}
.nav-1 .nav-item .nav-link:hover {
  color: red !important;
} */
.nav-1 {
  background-color: #fff;
}

.navbar-brand {
  color: rgba(255, 255, 255, 1) !important;
}


.section-3 .title-2 {
  margin-top: 10% !important;
}

.list-unstyled.text-dark.text-start li {
  white-space: nowrap;
}

.footer h5 {
  font-size: 13px !important;
}

.footer li a {
  font-size: 10px !important;
}

.footer p {
  font-size: 12px !important;
}

.btn-outline-dark {
  border-radius: 35px !important;
}

.section-3 h5 {
  font-size: 36px;
  font-family: "CustomToyota";
}

.section-3 p {
  font-size: 24px;
  font-family: "CustomToyota";
  margin-bottom: 6% !important;
}

.section-2 .wrapper {
  padding: 50px;
  background-color: rgb(250, 250, 250);
  border-radius: 20px;
}

.mx-boxes {
  margin-left: 8% !important;
  margin-right: 8% !important;
}


.section-2-content .main-heading h2 {
  font-size: 36px;
}

@media screen and (max-width: 580px) {
  .mx-boxes {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .section-2-content .main-heading h2 {
    font-size: 18px;
  }
}

.section-2-content p {
  color: #282c34;
  font-size: 16px;
}

.section-2-content {
  background-color: rgb(250, 250, 250);
  padding: 50px;
  width: 100%;
}

.section-2 .section-text {
  margin-left: 5% !important;
  margin-right: 5% !important;
}

.wrapper2.title h6 {
  font-size: 24px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.custom-bg-gray-dark {
  background-color: #ECEDED;
}

.custom-bg-gray {
  background-color: #F8F8F8;
}

.custom-list-style-number {
  list-style: linear-gradient;
}

.card {
  border-radius: 0% !important;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: none;
}

.back-to-top {
  cursor: pointer;
}

.back-to-top a {
  text-decoration: none !important;
  color: #282c34 !important;
}

.terbaik {
  font-size: 32px;
  font-weight: 700;
  margin-top: 6% !important;
  margin-bottom: 3% !important;
  font-family: "CustomToyota";
}

.Servis {
  color: #fff !important;
  background-color: rgba(0, 0, 0, 1) !important;
  border-radius: 12px !important;
  font-size: 15px !important;
  font-family: "CustomToyota";
  height: 44px;
  margin-top: 10px;
}

.Terbaik select,
.Terbaik #button-addon2 {
  /* border: 1px solid rgba(0, 0, 0, 1); */
  border-radius: 4px;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 1) !important;
  font-family: "CustomToyota";
}

.carousel-indicators [data-bs-target] {
  background-color: #fff;
  border: 2px solid #fff;
  /* Border color */
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
}

.carousel-indicators {
  position: absolute;
  bottom: 10% !important;
  transform: translateY(-50%);
  left: 50% !important;
  /* Adjust as needed */
  list-style: none;
  margin-left: 38% !important;
  margin-right: 0 !important;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}


.carousel-indicators li {
  display: inline-block;
  width: 10px;
  /* Diameter of the circle */
  height: 10px;
  margin: 0 5px;
  /* Adjust the spacing between circles */
  cursor: pointer;
  background-color: #000;
  border: 2px solid #fff;
  /* Border color */
  border-radius: 50%;
  /* Make it circular */
}

.carousel-indicators .active {
  background-color: red !important;
  /* Active indicator color */
}

.Terbaik input:focus {
  border: 1px solid rgba(0, 0, 0, 1) !important;
  font-family: "CustomToyota";
}

.fa-chevron-right,
.fa-chevron-left {
  color: #fff !important;
  background-color: #000 !important;
  padding: 10px 13px;
  border-radius: 50%;
}

.fa-chevron-right:hover,
.fa-chevron-left:hover {
  background-color: red !important;
}

.carousel-inner .carousel-item img {
  height: 100%;
  object-fit: cover;
}

.list {
  margin-top: 5% !important;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion-button {
  /* color: black !important; */
  outline: none !important;
  box-shadow: none !important;
  font-size: 12px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
}

.section-2-content .main-heading {
  margin-left: 10% !important;
  margin-right: 10% !important;
}

/* .section-2 .wrapper img{
  width: 50%;
  height: 100% !important;
} */
@media screen and (min-width: 1420px) {
  .section-2 .wrapper img {
    width: 75%;
    height: 100% !important;
  }

  .wrapper .toyota {
    width: 100% !important;
  }
}

@media screen and (max-width: 762px) {
  .section-2 .wrapper img {
    width: 60%;
    height: 100% !important;
  }

  .section-2-content .main-heading {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .section-3 h5 {
    font-size: 28px;
  }

  .section-2 .section-text {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .Servis {
    margin-top: 7px;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
    width: 50% !important;

  }

  /* .section-3 .card{
    width: 90% !important;
    margin-left: 10px;
  } */
  .logos img {
    width: 70% !important;
    height: auto !important;
  }

  .Terbaik select {
    font-size: 13px !important;
    margin-top: 8px !important;
  }

  .terbaik {
    font-size: 26px !important;
  }

  .section-3 h5 {
    font-size: 26px;
  }

  .section-3 p {
    font-size: 18px;
    margin-top: 5%;
  }

  .section-2 .wrapper {
    padding: 30px;
  }

  .section-2 .wrapper2img {
    width: 60%;
  }

  .section-2 .wrapper2h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .search-img {
    display: none;
  }

  .section-3 h5 {
    font-size: 36px;
  }

  .section-2-content {
    margin-left: 0% !important;
    width: 100% !important;
  }
}

.custom-select-container {
  position: relative;
}

.custom-select-container select {
  position: relative;
  z-index: 1;
}

.custom-select-container::after {
  content: '';
  position: absolute;
  top: 38%;
  right: 21%;
  transform: translateY(-50%);
  height: 73%;
  width: 1px;
  background-color: black;
  z-index: 2;
}

@media screen and (max-width: 1134px) {
  .custom-select-container::after {
    top: 37%;
    right: 25%;
    height: 74%;
  }
}

@media screen and (max-width: 1024px) {
  .custom-select-container::after {
    top: 36%;
    right: 26%;
    height: 72%;
  }
}

.custom_box_shadow {
  box-shadow: 0 1px 5px #0003 !important;
}

@media screen and (max-width: 992px) {
  .custom-select-container::after {
    right: 36%;
  }
}

@media screen and (max-width: 768px) {
  .custom-select-container::after {
    top: 35%;
    right: 34%;
    height: 70%;
  }
}

@media screen and (max-width: 576px) {
  .custom-select-container::after {
    top: 23%;
    right: 17%;
    height: 30%;
  }

  .carousel-indicators [data-bs-target] {
    height: 7px !important;
  }
}

@media screen and (min-width: 992px) {
  .home-tab a {
    /* padding:18px 115px !important; */
  }
}

#wrapper1 ul .whatsapp {
  width: 130px;
  height: 196px;
  position: relative;
  background: #000000;
  margin: 1px 0;
  cursor: pointer;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


@media screen and (min-width: 100px) and (max-width: 500px) {
  #wrapper1 ul .whatsapp {
    width: 115px;
    height: 170px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-top: -150px;
  }
}

@media screen and (min-width: 700px) and (max-width: 900px) {
  #wrapper1 ul .whatsapp {
    width: 115px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  #wrapper1 ul .whatsapp {
    width: 100px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  #wrapper1 ul .whatsapp {
    width: 116px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1200px) {
  #wrapper1 ul .whatsapp {
    width: 116px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  #wrapper1 ul .whatsapp {
    width: 106px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {

  #wrapper1 ul .whatsapp {
    width: 114px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {

  #wrapper1 ul .whatsapp {
    width: 124px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {

  #wrapper1 ul .whatsapp {
    width: 124px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1700px) and (max-width: 1800px) {

  #wrapper1 ul .whatsapp {
    width: 134px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1800px) and (max-width: 1900px) {

  #wrapper1 ul .whatsapp {
    width: 144px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 1900px) and (max-width: 2000px) {

  #wrapper1 ul .whatsapp {
    width: 144px;
    height: 196px;
    position: relative;
    border-radius: 10px 0px 0px 10px !important;
    background: #000000;
    margin: 1px 0;
    cursor: pointer;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}


/* .wrapper2 {
  position: fixed;
  bottom: 1%;
  right: -7.5%;
  transform: translate(-50%, -50%);
  z-index: 10000;
} */

#wrapper2 ul {
  list-style: none;
}

/* #wrapper2 ul .whatsapp {
  width: 180px;
  height: 30px;
  position: relative;
  border-radius: 10px 0px 0px 10px !important;
  background: #D52027;
  margin: 1px 0;
  cursor: pointer;
  border-radius: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
} */

#wrapper2 ul li .fa {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
}

.wrapper1 {
  position: fixed;
  bottom: 12%;
  right: -10%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

#wrapper1 ul {
  list-style: none;
}



#wrapper1 ul li .fa {
  position: absolute;
  top: 40%;
  left: 15%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
}


.sideoption {
  font-family: "CustomToyota";
  font-weight: 500;
  font-size: 15px;
  color: white;
}

.sideoption2 {
  font-family: "CustomToyota";
  font-weight: 100;
  font-size: 15px;
  color: white;
  rotate: 90deg;
  margin-top: 60px;
  margin-left: 9px;
  width: 115px;
}

.side-img {
  position: relative;
  max-width: 17px;
}



@media (min-width: 1325px) and (max-width: 1480px) {

  .wrapper2 {
    right: -8.5%;
    /* Adjust the percentage to your preference */
  }

  .sideoption2 {
    font-family: "CustomToyota";
    font-weight: 100;
    font-size: 15px;
    color: white;
    rotate: 90deg;
    margin-top: 60px;
    margin-left: 9px;
    width: 215px;
  }
}

@media (max-width: 1220px) {

  /* Adjust this breakpoint as needed */
  .wrapper1 {
    right: -13%;
    /* Adjust the percentage to your preference */

  }
}

@media (max-width: 1050px) {

  /* Adjust this breakpoint as needed */
  .wrapper2 {
    right: -13%;
    /* Adjust the percentage to your preference */
  }
}

@media (max-width: 970px) {

  /* Adjust this breakpoint as needed */
  .wrapper1 {
    right: -18%;
    /* Adjust the percentage to your preference */
  }
}

@media (max-width: 830px) {

  /* Adjust this breakpoint as needed */
  .wrapper2 {
    right: -16%;
    /* Adjust the percentage to your preference */
  }

  .wrapper1 {
    right: -20%;
    /* Adjust the percentage to your preference */
  }
}

@media (max-width: 650px) {
  .wrapper2 {
    right: -20%;
  }

  .wrapper1 {
    right: -24%;
  }
}

@media (max-width: 530px) {
  .wrapper2 {
    right: -26%;
  }

  .wrapper1 {
    right: -29%;
  }
}

@media (max-width: 435px) {

  .wrapper2 {
    right: -30%;
  }

  .wrapper1 {
    right: -35%;
  }
}

@media (max-width: 420px) {

  .wrapper2 {
    right: -33%;
  }

  .wrapper1 {
    right: -41%;
  }
}

@media (max-width: 330px) {
  .wrapper2 {
    right: -45%;
  }

  .wrapper1 {
    right: -47%;
  }
}

.spinner-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(247, 245, 245);
}

.Main_transition_add {
  transition: all 1s ease-in !important;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 1s ease !important;
}

.custom_margin_header_bottom {
  margin-bottom: 125px !important;
}

@media (max-width:991px) {
  .color_in_mobile {
    background-color: white !important;
  }

  .nav-item a {
    color: black !important;
    text-align: left !important;
  }

  .nav-item:not(:last-child) {
    border-bottom: 1px solid #D0D4D3 !important;
    /* Change color as needed */
  }

  .navbar_bottom_custom_padding {
    padding-bottom: 0px !important;
  }

  .navbar_bottom_custom_padding_1 {
    margin-bottom: 12px !important;
  }

  .navbar_bottom_custom_padding_2 {
    margin-bottom: 12px !important;
  }

  .nav-item a:hover {
    color: #D52027 !important;
  }

  #navbarNav3 .nav-item:not(:last-child) {
    border-bottom: 0px solid !important;
    /* Change color as needed */
  }

  #navbarNav3 .nav-item {
    padding-left: 15px !important;
  }

  #navbarNav4 .nav-item:not(:last-child) {
    border-bottom: 0px solid !important;
    /* Change color as needed */
  }

  #navbarNav4 .nav-item {
    padding-left: 15px !important;
  }

  #navbarNav3 .nav-item a {
    font-style: normal !important;
    font-weight: 400 !important;
  }

  #navbarNav4 .nav-item a {
    font-style: normal !important;
    font-weight: 400 !important;
  }

  .custom-class_no_border {
    border: 0px !important;
  }
}

.custom_active_class {
  background-color: #ffffff !important;
  color: #000 !important;
}

@media only screen and (min-width: 990px) {

  .custom_active_class {

    background-color: white !important;
    color: #000 !important;
  }
}

.list-unstyled li a:hover {
  color: #D52027 !important;
}

.pdf-embed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.mobile-section {
  text-align: center !important;
}

.text-center {
  text-align: center !important;
}

.socialMedia {
  max-height: 28px;
}

.css-13cymwt-control {
  height: 45px !important;
}

/* CSS to target the react-select input and remove the outline */
.react-select__input-container input {
  outline: none !important;
}