/* .nav-tabs .nav-link.active {
    color: black !important;
    border-bottom: 4px solid #000 !important;
} */
.nav-tabs {
    /* border-bottom: 2px solid #D8D8D8 !important; */
    border-width: 50% !important;
}

.tips-event p {
    font-size: 14px !important;
    font-weight: 400;
    margin-top: -12px;
}

.card-title p {
    font-size: 10px !important;
}

.event-tabs .nav-link {
    color: #000 !important;
    border: none !important;
}

.card {
    height: 100%;
}

.title-color {
    color: #000 !important;
    color: rgba(213, 32, 39, 1) !important;
}

.tab-pane button {
    text-align: center !important;
}

@media only screen and (max-width: 768px) {
    .title-color {
        color: rgba(213, 32, 39, 1) !important;
    }

    .event-tabs .card {
        width: 100% !important;
    }

    .event-tabs .card-body .custom_button_forcolor {
        border: none;
        text-align: left !important;
        color: rgba(213, 32, 39, 1) !important;
        margin-left: -10px !important;
    }

    .event-tabs .card-body button:hover {
        background-color: #fff !important;
    }

    .event-tabs .fa-chevron-right {
        color: rgba(213, 32, 39, 1) !important;
        background-color: #D8D8D8;
        padding: 5px !important;
    }

    .event-tabs .fa-chevron-right:hover {
        background-color: #fff !important;
    }
}


.custom_heading_weight {
    font-family: "CustomToyotaLight";
    font-weight: 300 !important;
}

.custom-b-weight {
    font-weight: 700 !important;
    font-size: 18px !important;
}

@media only screen and (max-width: 600px) {
    .custom-b-weight {
        font-size: 14px !important;
    }
}

.fixed_height {
    height: 200px !important;
    width: auto !important;
    object-fit: cover;
    margin-left: auto;
}

.parent-container {
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    width: auto !important;
    overflow: hidden !important;
    /* Ensure the image does not exceed the container */
}


.custom-nav-tabss {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch !important;
    scrollbar-width: none !important;
    /* Hides the scrollbar for Firefox */
    -ms-overflow-style: none !important;
    /* Hides the scrollbar for IE and Edge */
}

@media (min-width:440px) {
    .custom-nav-tabs_1 {
        display: flex !important;
        justify-content: center !important;
    }
}

@media (max-width:440px) {
    .custom-nav-tabs_1 {
        display: flex !important;
        justify-content: left !important;
    }

    .custom-nav-tabss {
        padding-left: 100px !important;
    }
}

@media (min-width:1920px) {
    .latest-news-wrapper .carousel-item img {
        width: 100% !important;
    }
}