.section-1 {
    background-color: rgb(250, 250, 250);
    padding: 5%;
}

.section-1 .section-content_12 p {
    text-align: left;
    color: rgba(0, 0, 0, 1);
    font-size: 1.125rem;
}

.Check {
    color: rgba(0, 0, 0, 1);
}

.tabs.active {
    background-color: rgba(223, 29, 29, 1) !important;
    color: #fff !important;
    border: 1px solid rgb(223, 29, 29) !important;
}

.sertifikat {
    color: #000 !important;
    border: 1px solid #B7B7B7;
    border-radius: 20px 0px 0px 0px !important
}

.cataten {
    color: #000 !important;
    border: 1px solid #B7B7B7;
    border-radius: 0px 20px 0px 0px !important;
}

.round-tabs .nav-link {
    border-radius: 50%;
    /* Adjust the value as needed */
}

.circle-tab {
    position: relative;
}

.circle-tab a {
    position: relative;
    /* Add position relative to the anchor tag */
    display: inline-block;
    width: 50px;
    /* Adjust the width of each tab */
    height: 50px;
    /* Adjust the height of each tab */
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    /* Make the tabs circular */
    background-color: #ccc;
    /* Default background color */
    color: #333;
    /* Default text color */
    text-decoration: none;
    z-index: 10;
}

/* Add the line between circles */
.circle-tab .active:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -40px;
    /* Adjust the position of the line */
    width: 45px;
    /* Adjust the width of the line */
    height: 3px;
    /* Adjust the height of the line */
    background-color: rgba(223, 29, 29, 1);
    /* Color of the line */
    z-index: -1;
    /* Send the line behind the circles */
    margin-right: 13px !important;
}

.circle-tab a:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -40px;
    /* Adjust the position of the line */
    width: 45px;
    /* Adjust the width of the line */
    height: 3px;
    /* Adjust the height of the line */
    background-color: #ccc;
    /* Color of the line */
    z-index: -1;
    /* Send the line behind the circles */
    margin-right: 13px !important;
}

.circle-tab a.active {
    background-color: rgba(223, 29, 29, 1);
    /* Active background color */
    color: #fff;
    /* Active text color */
}

.circle-tabers {
    position: relative;
}

.circle-tabers a {
    position: relative;
    /* Add position relative to the anchor tag */
    display: inline-block;
    width: 50px;
    /* Adjust the width of each tab */
    height: 50px;
    /* Adjust the height of each tab */
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    /* Make the tabs circular */
    background-color: #ccc;
    /* Default background color */
    color: #333;
    /* Default text color */
    text-decoration: none;
    z-index: 10;
}

/* Add the line between circles */
.circle-tabers a:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -40px;
    /* Adjust the position of the line */
    width: 45px;
    /* Adjust the width of the line */
    height: 3px;
    /* Adjust the height of the line */
    background-color: #999;
    /* Color of the line */
    z-index: -1;
    /* Send the line behind the circles */
    margin-right: 13px !important;
}

.circle-tabers a.active {
    background-color: rgba(223, 29, 29, 1);
    /* Active background color */
    color: #fff;
    /* Active text color */
}

.circle-tabs {
    position: relative;
}

.circle-tabs a {
    position: relative;
    /* Add position relative to the anchor tag */
    display: inline-block;
    width: 50px;
    /* Adjust the width of each tab */
    height: 50px;
    /* Adjust the height of each tab */
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    /* Make the tabs circular */
    background-color: #ccc;
    /* Default background color */
    color: #333;
    /* Default text color */
    text-decoration: none;
    z-index: 10;
}

/* Add the line between circles */
.circle-tabs a:not(:first-child)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -40px;
    /* Adjust the position of the line */
    width: 45px;
    /* Adjust the width of the line */
    height: 3px;
    /* Adjust the height of the line */
    background-color: #999;
    /* Color of the line */
    z-index: -1;
    /* Send the line behind the circles */
    margin-right: 13px !important;
}

.circle-tabs a.active {
    background-color: rgba(223, 29, 29, 1);
    /* Active background color */
    color: #fff;
    /* Active text color */
}


.tabs-section {
    background-color: rgb(250, 250, 250);
    width: 100%;
    height: 90%;
    border: 1px solid rgba(183, 183, 183, 1);
    border-top: none !important;
    border-radius: 0px 0px 20px 20px;
}

.Masukkan {
    border: none;
    outline: 1px solid rgba(223, 29, 29, 1);
    background-color: white !important;
}

.Lanjut {
    background-color: rgba(223, 29, 29, 1) !important;
    border: 1px solid rgba(223, 29, 29, 1);
    border-radius: 16px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.lihat_Diri {
    background-color: white !important;
    border: 1px solid rgba(223, 29, 29, 1);
    border-radius: 16px;
    color: #000000;
}

.ubah_diri {
    color: red;
    font-weight: bolder;
}

.ubah_diri:hover {
    color: blue;
    font-weight: bolder;
    cursor: pointer;
}

.Lanjut:hover {
    background-color: white !important;
    color: #000000;
    border: 1px solid rgba(223, 29, 29, 1);
}

.lihat_Diri:hover {
    background-color: rgba(223, 29, 29, 1) !important;
    border: 1px solid rgba(223, 29, 29, 1);

}

.lihat_Diri_1 {
    background-color: white !important;
    border: 1px solid rgba(223, 29, 29, 1);
    border-radius: 16px;
    color: #000000;
}

.lihat_Diri_1:hover {
    background-color: rgba(223, 29, 29, 1) !important;
    border: 1px solid rgba(223, 29, 29, 1);

}

.lihat_Diri_2 {
    background-color: rgba(223, 29, 29, 1) !important;
    border: 1px solid rgba(223, 29, 29, 1);
    border-radius: 16px;
}

.lihat_Diri_2:hover {
    background-color: white !important;
    border: 1px solid rgba(223, 29, 29, 1);
    color: #000000;

}

@media (max-width:768px) {
    .lihat_Diri_1 img {
        display: none;
    }
}

.Masukkan p {
    font-size: 14px !important;
}

.section-2 .wrapper {
    border: 1px solid #000;
}

.faq .accordion-button::after {
    font-weight: 900;
    /* background-color: #fff !important;  */
    border-radius: none;
    padding: 4px 10px 4px 10px;
    background-image: none !important;
    /* content: " " !important;  */
    font-size: 1rem;
    color: white;
    font-size: 1.5rem !important;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {

    .section-2 .section-content_12 {
        text-align: center !important;
        margin-bottom: 15px !important;
    }

    .express {
        width: 100% !important;
    }
}

strong {
    font-weight: 600;
}

.circle-tabs a.before-active {
    background-color: rgba(223, 29, 29, 1) !important;
}

.circle-tabers a.before-active {
    background-color: rgba(223, 29, 29, 1) !important;
}

.tabs-section {
    height: auto !important;
}

.download_pdf_buttons {
    display: flex;
    padding: 15.49px;
    justify-content: center;
    align-items: center;
    gap: 48.98px;
    border-radius: 36px;
    background: #DF1D1D;
    border: none;
}

.download_pdf_buttons1 {
    width: 81px;
    display: flex;
    padding: 15.49px;
    justify-content: center;
    align-items: center;
    gap: 48.98px;
    border-radius: 36px;
    background: #DF1D1D;
    border: none;
}

.buttons_custom_gap {
    gap: 48.98px;
}

@media (max-width:992px) {

    /* .download_pdf_buttons{
    padding: 23.49px;
    } */
    .download_pdf_buttons svg {
        width: 20.105px;
        height: 20.105px;
    }

    .download_pdf_buttons1 svg {
        width: 20.105px;
        height: 20.105px;
    }

    .download_pdf_buttons1 {
        width: 54px;
    }

    .custom_size_of_font p {
        font-size: 10px !important;
    }

    .buttons_custom_gap {
        gap: 24px;
    }
}

@media (max-width:768px) {

    /* .download_pdf_buttons{
    padding: 23.49px;
    } */
    .download_pdf_buttons svg {
        width: 20.105px;
        height: 20.105px;
    }

    .download_pdf_buttons1 svg {
        width: 20.105px;
        height: 20.105px;
    }

    .download_pdf_buttons1 {
        width: 52px;
    }

    .custom_size_of_font p {
        font-size: 10px !important;
    }

    .buttons_custom_gap {
        gap: 24px;
    }
}

.tab-bold-p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.424px;
}

.tab-bold {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
}

.Langkah_side_text {
    color: #DF1D1D;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.424px;
}

.tab-light-bold-p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.tab-light-bold-p1 {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.Masukkan::placeholder {
    color: #737677;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 33.424px;
}

@media (max-width:992px) {
    .Langkah_side_text {
        font-size: 13px !important;
    }

    .li_text_1 {
        font-size: 12px !important;
    }

    .tab-bold-p {
        font-size: 14px !important;
    }

    .tab-light-bold-p {
        font-size: 12px !important;
    }

    .Masukkan::placeholder {
        font-size: 10px;
        /* Adjust the font size as needed */
    }

    .Lanjut {
        font-size: 12px !important;
    }

    .lihat_Diri_1 {
        font-size: 12px !important;
    }

    .lihat_Diri {
        font-size: 12px !important;
    }
}

@media (max-width:768px) {
    .Langkah_side_text {
        font-size: 10px !important;
    }

    .li_text_1 {
        font-size: 12px !important;
    }

    .tab-bold-p {
        font-size: 14px !important;
    }

    .tab-light-bold-p {
        font-size: 12px !important;
    }

    .Masukkan::placeholder {
        font-size: 10px;
        /* Adjust the font size as needed */
    }

    .Lanjut {
        font-size: 12px !important;
    }

    .lihat_Diri_1 {
        font-size: 12px !important;
    }

    .lihat_Diri {
        font-size: 12px !important;
    }
}

.input_label {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.424px;
    /* 278.531% */
}

.otp {
    border-radius: 4px;
    border: 1px solid #B7B7B7;
    background: #FFF;
    width: 42px;
    height: 42px;
    margin-left: 10px;
}

.active1 {
    border-radius: 16px;
    background: #D71921;
    border: 2px solid #D71921;
    color: #FFF;
    font-weight: 600;
}

.active1:hover {
    color: #FFF;
}

.non_active {
    border-radius: 16px;
    border: 2px solid #D71921;
    background: #FFF;
    color: #000;
    font-weight: 600;
}

.non_active:hover {
    color: #000;
}

.active1:focus {
    color: #FFF;
    font-weight: 600;
}

.add_custom_properties {
    border: 1px solid rgba(183, 183, 183, 1);
    border-top: none !important;
    border-radius: 0px 0px 20px 20px;
    height: 450px;
}

.tab-discription-p {
    color: #000 !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 30px !important;
}

.tab-info-p {
    color: #000 !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 30px !important;
}

.custom_discrip_tab {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.custom_class_tab_discrip {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.custom_table_heading th {
    background-color: #D71921;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom_table_heading td {
    color: var(--Gray-70, #737677);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.spinner-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(247, 245, 245, 0.733);
}

.custom_border_table {
    border-top-left-radius: 20px !important;
}

.custom_border_table1 {
    border-top-right-radius: 20px !important;
}

.custom_border_table3 {
    border-bottom-right-radius: 20px !important;
    border: none;
}

.custom_border_table2 {
    border-bottom-left-radius: 20px !important;
    border: none;
}

.custom_border_set_td {
    color: var(--Gray-70, #737677);
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}

.custom_border_tr {
    border-radius: 0px 0px 24px 24px;
    border-right: 1px solid var(--Gray-30, #E3E3E4);
    border-bottom: 1px solid var(--Gray-30, #E3E3E4);
    border-left: 1px solid var(--Gray-30, #E3E3E4);
}

.custom-table {
    border-spacing: 0;
    width: 100%;
    border-radius: 30px;
}

.custom-table tbody {
    border-bottom-left-radius: 15px;
    /* Adjust radius as needed */
    border-bottom-right-radius: 15px;
    /* Adjust radius as needed */
}

.custom_wrong_chase {
    color: #DF1D1D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}


.recommend-section {
    font-size: 28px;
    font-weight: bolder;
}

.container-recommend {
    margin-top: 100px;
    margin-bottom: 100px;
}

.agreement {
    text-align: left;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 13px;
}

.img-extra {
    height: 100px;
    width: auto;
    margin-left: 20px;
}

.img-fluid {
    height: auto;
    width: auto;
}

.img-logo {
    height: auto;
    width: auto;
}

.tiny-text {
    font-size: 12px;
}

.link-href {
    text-decoration: underline;
    font-size: 13px;
    ;
}

.card-body {
    /* border-radius: 20px !important; */
    background-color: #F8F8F8;
}

.card {
    /* border-radius: 10px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-title {
    border-bottom: none;
    font-size: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: left;
    padding-left: 20px;
}

.card-header span {
    color: #d9534f;
}

.table th {
    background-color: #d9534f;
    color: white;
    text-align: center;
    font-size: 0.8rem;
    vertical-align: middle;
}

.table td {
    text-align: center;
}

.table td:nth-child(4) {
    color: #d9534f;
}

.table td:nth-child(4).yes {
    color: #5cb85c;
}

.table-responsive {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 20px;
    margin-right: 20px;
}

.bk-info{
    background-color: #dfdede;
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
}

.bk-program{
    background-color: #E4E4E4;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.d-none{
    display: none;
}