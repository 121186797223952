/* General Styling */
p,
li,
.content-text,
.content p,
.content li,
ul li {
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
}

.title-text {
    font-weight: bolder !important;
    font-style: italic;
    font-size: 18px !important;
}

.subtitle
{
font-weight: bolder !important;
font-style: italic;
}

.text-center {
    font-size: 14px !important;
    font-weight: 500;
}

.round-back {
    background-color: rgb(238, 236, 236);
    padding: 50px 10px;
    border-radius: 10px;
}

.content {
    max-width: 800px;
    margin: auto;
}

/* Card Body */
.card-body {
    padding: 20px;
}

/* Sidebar Styling */
.sidebar {
    height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    cursor: pointer;
    color: #d80b0b !important;
    margin-bottom: 10px;
    font-weight: bolder !important;
}

.sidebar li:hover {
    text-decoration: underline;
}

/* Main Content Styling */
.main-content {
    height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
}

.main-content .section {
    margin-bottom: 20px;
}

/* Button Styling */
.btn-done {
    background-color: #b71c1c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-done:hover {
    background-color: grey;
    color: white;
}

/* Modal Styling */
.modal-content {
    background-color: #fff;
    padding: 0 !important;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
}

/* Table Styling */
.table-container {
    margin: 20px;
}

.table th {
    /* background-color: #f8f9fa; */
    font-weight: bold;
}

.table td {
    vertical-align: top;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .content {
        padding: 0 15px;
    }

    .sidebar {
        display: none;
        /* Hide sidebar on smaller screens */
    }

    .main-content {
        height: auto;
        padding: 15px;
    }

    .round-back {
        padding: 30px 10px;
    }

    .text-center {
        font-size: 12px;
    }

    .btn-done {
        padding: 8px 16px;
        font-size: 14px;
    }

    .card-body {
        padding: 15px;
    }

    .modal-content {
        width: 95%;
    }
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    display: flex;
    align-items: flex-start;
    color: #d80b0b !important;
    margin-bottom: 10px;
    font-weight: bolder !important;
    cursor: pointer;
}

.sidebar li:hover {
    text-decoration: underline;
}

.sidebar .number {
    margin-right: 8px;
    /* Space between number and text */
    flex-shrink: 0;
    /* Prevents the number from wrapping */
}

.sidebar .item-text {
    flex: 1;
    /* Allows the text to wrap while aligning left */
    text-align: left;
    word-break: break-word;
    /* Ensures long words break properly */
}