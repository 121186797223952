.custom-btn-dark
{
  border-radius: 16px;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid black;
}
.custom-btn-dark:hover
{
  background-color: #000 !important;
  color: #fff !important;
}
.container-fluid {
  overflow-x: hidden; /* Hides horizontal overflow */
}
.custom_color_11
{
  color: #1E1E1E !important;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
letter-spacing: -0.198px;
}
.btn.btn-dark {
    border-radius: 13px;
  }
   .Banner_image
   {
   display: flex;
   height: 519px;
   flex-direction: column;
   align-items: center;
   gap: 10px;
   background-image: url(/public/assets/Rectangle4.png);
   background-position: center;
   background-size: cover;
   }
   @media only screen and (min-width: 990px) {
    .custom_height
    {
      height: 100% !important;
    }
   }
   @media only screen and (max-width: 600px) {
    .Banner_image
    {height: 105.422px;
      flex-shrink: 0;
    }
   }
   @media (min-width: 600px) and (max-width: 800px) {
    .Banner_image
    {height: 305.422px;
    }
   }
   .paraTag
   {
    color: #161A1D;
   text-align: center;
   line-height: 120%; /* 28.8px */
   }
   .paraTag1
   {
     color: #D71921;
     font-weight: 300 !important;
     text-transform: uppercase !important;
   }
   .custom-bg-gray 
   {
    background-color: #F8F8F8 !important;
   }
   
 /* //this for the Navs and Tabs  */
 /* .nav-tabs
 {
    border-bottom: 1px solid #D8D8D8 !important; 
 } */
 .custom-nav-fill .nav-item .nav-link,
 .nav-justified .nav-item .nav-link {
   border: none !important;
   width: 100%;
   color: #000;
 }
.nav-tabs .nav-link.active {
    border: none !important;
    /* background-color: #fff !important; */
    color: #000 !important;
    border-bottom: 4px solid #EB0A1E !important;
  }
  .nav-tabs .nav-link {
    border-top-left-radius: 0rem !important;
    border-top-right-radius: 0rem !important;
  }
  .nav-tabs .nav-link.active {
    border-color: none !important ;
    /* background-color: #60D0FF; */
    color: #fff;
    font-weight: 600;
  }
  /* .nav-link {
    height: 77px;
    font-size: 13px;
  } */
  .custom-nav-link:hover {
    border: none !important;
    border-bottom: 1px solid #D8D8D8 !important;
  }
  .nav-link:focus {
    outline: none;
  }
  .tab-pane {
    height: auto;
  }
  .form-control {
    height: 45px;
    position: relative;
  }
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: none !important;
  }
  .flight-where-from:after {
    position: absolute;
    content: '23';
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .scroll-y {
    overflow-y: scroll;
    height: 300px;
  }
  .faq .accordion-button::after {
    background-color: #fff;
    content: url(/public/assets/Vector1.svg);
    padding: 0px !important;
}
.faq .accordion-button:not(.collapsed)::after {
    font-weight: 600;
    padding: 0px !important;
    content: url(/public/assets/Vector.svg);
    transform: none !important;
}
.back_color_set
{
  border-bottom:1px solid #D8D8D8 ;
  padding: 10px;
}
.custom_font
{
    font-size: 36px; 
    font-weight: 600;
    padding-right: 10px;
}


.custom-nav-tabs {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap; /* Prevents wrapping */
  scrollbar-width: none; /* Hides the scrollbar for Firefox */
  -ms-overflow-style: none; /* Hides the scrollbar for IE and Edge */
}

.custom-nav-tabs::-webkit-scrollbar {
  display: none; /* Hides the scrollbar for Webkit (Chrome, Safari, etc.) */
}

.custom-nav-item {
  flex: 0 0 auto;
}

.custom-nav-link {
  white-space: nowrap; /* Prevents text wrapping */
}
.Services_paragraph{
color: rgba(0, 0, 0, 0.80);
line-height: 120%; /* 21.6px */
letter-spacing: -0.198px;
}
.heading_discription
{color: #161A1D;
  font-size: 24px !important;
  font-weight: 400;
  line-height: 120%;


}
@media only screen and (max-width: 600px) {
  h1 {
    font-family: "CustomToyota";
  font-size: 1.1rem !important;
  font-style: normal;
  font-weight: 600 !important; 
  }
  b{
    font-family: "CustomToyota";
    font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important; 
  }
  .heading_discription{
    font-size: 0.875rem !important; 
  font-style: normal !important;
  font-weight: 400 !important; 
  }
  .custom-font-light-one-rem{
    font-family: "CustomToyota";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300; 
  color: #161A1D; 
  }
}
.custom_color_1
{
  color: #161A1D !important;
  font-style: normal;
font-weight: 300;
line-height: 120%; /* 16.8px */
}

@media (min-width: 990px) and (max-width: 1200px) {
  .custom_image_set
  {
    height: auto !important;
  }
  .custom_height_set{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}